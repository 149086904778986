import { React, useState, useEffect } from 'react'
import videoimg from "../../../images/r-video1.png";
import playIcon from "../../../images/play.png";
import pdfIcon from "../../../images/pdf.png";
import meetIcon from "../../../images/Virtual.png";
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';

function CourseInstruction({ courseData }) {
    const navigate = useNavigate();
    let [isShowLoader, SetIsShowLoader] = useState(false);

    let enrollNow = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');

        if (token != null && token != "") {
            enrollCourseFunc(courseData)
        } else {
            localStorage.setItem('route_url', `/course-details/${courseData.id}?name=${courseData.name}`);
            toast.error('Please login to enroll the course');
            navigate('/login');
        }
    }

    let enrollCourseFunc = (data) => {
        toast.dismiss();
        SetIsShowLoader(true)
        const URL = config.apiBaseUrl + `auth/enroll-course`;
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };
        axios.post(URL, { "course_id": parseInt(data.id) }, httpConfig)
            .then((response) => {
                SetIsShowLoader(false);
                if (response.data.status == 200) {
                    toast.success(response.data.response);
                    navigate('/enrolled-course');
                } else {
                    toast.error(response.data.response);
                }
            }).catch(err => {
                SetIsShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    let goToCourse = (e, data) => {
        e.preventDefault();
        window.open(data?.url + data?.id, '_blank')
    }

    useEffect(() => {
    }, [])

    return (
        <div className="instruction">
            <h4>Instruction</h4>
            <div className="video-card">
                <div className="card">
                    {
                        courseData?.instruction_file ? <div className="imagearea">
                            <iframe src={config.assetsBaseUrl + courseData?.instruction_file} className="card-img-top" alt="..." height={185}></iframe>
                            {/* <img src={playIcon} className="paly" /> */}
                        </div> : <></>
                    }

                    <div className="card-body">
                        <h5 className="card-title">{courseData?.instruction_title}</h5>
                        <p className="card-text">
                            <span dangerouslySetInnerHTML={{ __html: courseData?.instruction }}></span>
                        </p>
                        {
                            courseData?.isEnrolled ? <a href="" className="btn" onClick={e => goToCourse(e, courseData)}>Go to Course
                                {isShowLoader ? <i className="fa fa-spinner ml-2" aria-hidden="true"></i> : ''}
                            </a> : <a href="" className="btn" onClick={(e) => enrollNow(e)}>Enroll Now
                                {isShowLoader ? <i className="fa fa-spinner ml-2" aria-hidden="true"></i> : ''}
                            </a>
                        }
                    </div>
                </div>
            </div>
            <div className="personal-goal">
                <h6>Personal goal setting exercise</h6>
                <p>
                    {courseData?.personal_goal ? <span dangerouslySetInnerHTML={{ __html: courseData?.personal_goal }}></span> : <span className='nodataavailable'>no data available</span>}

                </p>
            </div>
            <div className="Case-exercise">
                <h6>Case exercise</h6>
                <p>
                    {courseData?.case_exercise ? <span dangerouslySetInnerHTML={{ __html: courseData?.case_exercise }}></span> : <span className='nodataavailable'>no data available</span>}
                </p>
            </div>
            <div className="work-book">
                <h6>Participant work book</h6>
                {
                    courseData?.course_work_books?.map((data) => {
                        return <div className="book text-center">
                            <img src={pdfIcon} />
                            <p class="text-start">{data?.name}</p>
                        </div>
                    })
                }
                {
                    courseData?.course_work_books?.length == 0 ? <span className='nodataavailable'>no data available</span> : <></>
                }
            </div>
            <div class="Virtual-mentoring">
                <h6>Virtual mentoring</h6>
                <img src={courseData?.virtual_mentoring_file ? config.assetsBaseUrl + courseData?.virtual_mentoring_file : meetIcon} />
                <p class="mt-3">
                    <span dangerouslySetInnerHTML={{ __html: courseData?.virtual_mentoring }}></span>
                </p>
            </div>
        </div>
    )
}

export default CourseInstruction