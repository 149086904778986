import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';

function ForgotPassword({ isShowLoader }) {
  //declare form field
  let [formValue, setFormValue] = useState({
    "email": ""
  });
  const navigate = useNavigate();

  //form submit function
  let formSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    let isValidate = formValidation(formValue);

    if (isValidate) {
      isShowLoader(true);
      axios.post(config.apiBaseUrl + 'auth/forgot-password', formValue)
        .then((response) => {
          isShowLoader(false);
          if (response.data.status == 200) {
            toast.success(response.data.response);
            navigate('/');
          } else {
            toast.error(response.data.response);
          };
        }).catch(err => {
          isShowLoader(false);
          toast.error(variables.someErrorOccurred);
        })
    }
  }

  //form validation function
  let formValidation = (data) => {
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (data.email === null || data.email === "") {
      toast.error("Email is required");
      return false;
    }
    if (!emailPattern.test(data.email)) {
      toast.error("Enter valid email");
      return false;
    }
    return true;
  }

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  useEffect(() => {
    isShowLoader(false);
  }, [])

  return (
    <div>
      <section className="loginfrom page_section mb-5">
        <div className="inner_page mb-4">
          <div className="container">
            <div className="page_content">
              <h2 className="page-heading">Forgot Password</h2>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="">
            <div className="loginlayer">
              <form className="contactusform">
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Email*" type="text" name="email" onChange={e => setFormValue({ ...formValue, email: e.target.value })} value={formValue.email} />
                  </div>
                </div>
                <a href="" className="submit_button submitbtn" onClick={(e) => formSubmit(e)}>Submit</a>
              </form>
              <div className="back">
                <a href="" className=" submitbtn" onClick={(e) => changeRoute(e, '/login')}>
                  Back to Login</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ForgotPassword