import { React, useState, useEffect } from 'react';
import config from '../../../constants/Env';
import axios from "axios";
import Banner from './Banner';
import Course from './Course';
import variables from '../../../constants/Variables';
import { toast } from 'react-toastify';
import noImage from "../../../images/no-image.jpeg";
import { useNavigate } from 'react-router-dom';

function Home({ isShowLoader }) {
  //declare state
  let [dataList, setDataList] = useState([]);
  let [itemPerPage, setItemPerPage] = useState(8);
  let [totalCourseData, setTotalCourseData] = useState([]);

  //it is used for navigation
  const navigate = useNavigate();

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  //get data function
  let getPageData = () => {
    axios.post(config.apiBaseUrl + 'course-list', {})
      .then((response) => {
        isShowLoader(false);
        if (response.data.status == 200) {
          totalCourseData = response.data.data;
          setTotalCourseData(response.data.data);
          setDataList(totalCourseData.slice(0, itemPerPage));

        }
      }).catch(err => {
        isShowLoader(false);
        toast.error(variables.someErrorOccurred);
      })
  }

  //load more data function
  let loadMoreData = () => {
    itemPerPage = itemPerPage + 8;
    setItemPerPage(itemPerPage);
    setDataList(totalCourseData.slice(0, itemPerPage));
  }

  //call get data function
  useEffect(() => {
    isShowLoader(true);
    getPageData();
  }, [])

  return (
    <div>
      <Banner />
      {/* {dataList.length > 0 && <Course courseList={dataList} />} */}
      <section className="main-boy-sec">
        <div className="container">
          <div className="row">
            {dataList.map((item, i) => {
              return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
                <div className="nula-sec">
                  <img src={item.image_download_url ? item.image_download_url : noImage} alt="Course" />

                  <div className="nuladetail">
                    <p>{item.name}</p>
                  </div>

                  <a href="" className="pluslink" onClick={(e) => changeRoute(e, `/course-details/${item.id}?name=${item.name}`)}>
                    <svg width="109" height="74" viewBox="0 0 109 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.954 61H0.5L3.31033 65.8989L109 74V0.511208C105.727 0.125443 97.3339 -0.414635 89.9413 0.511208C89.9413 8.8438 83.0109 10.9269 79.5456 10.9269H42.0055C34.613 10.9269 32.3798 17.485 32.1873 20.764V44.4888C32.1873 58.3765 21.3444 61.3858 15.954 61Z"
                        fill="white" />
                      <rect x="39" y="17" width="51" height="51" rx="25.5" fill="#337AB7" />
                      <path
                        d="M70.8574 43.6426H66.6426V47.8574C66.6426 48.4885 66.131 49 65.5 49C64.869 49 64.3574 48.4885 64.3574 47.8574V43.6426H60.1426C59.5115 43.6426 59 43.131 59 42.5C59 41.869 59.5115 41.3574 60.1426 41.3574H64.3574V37.1426C64.3574 36.5115 64.869 36 65.5 36C66.131 36 66.6426 36.5115 66.6426 37.1426V41.3574H70.8574C71.4885 41.3574 72 41.869 72 42.5C72 43.131 71.4885 43.6426 70.8574 43.6426Z"
                        fill="white" />
                    </svg>
                  </a>
                </div>
              </div>
            })}
            {totalCourseData.length == 0 ? <span className="nodatafound">Coming soon</span> : <span></span>}
            {totalCourseData.length > 0 && itemPerPage < totalCourseData.length ? <button type="button" className="loadmorebtn mx-auto" onClick={() => loadMoreData()}>Load More</button> : <span></span>}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home