import { HashRouter, BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { React, useState, useEffect } from 'react'
import Home from './views/cms/HomePage/Home';
import Header from './partials/Header';
import Footer from './partials/Footer';
import './App.css';
import './css/style.css';
import './css/media.css';
import Loader from './partials/Loader'
import FAQ from './views/cms/FAQ/FAQ';
import PrivacyPolicy from './views/cms/PrivacyPolicy/PrivacyPolicy';
import About from './views/cms/About/About';
import Contact from './views/cms/Contact/Contact';
import Login from './views/auth/Login/Login';
import Register from './views/auth/Register/Register';
import ForgotPassword from './views/auth/ForgotPassword/ForgotPassword';
import Dashboard from './views/after-login/Dashboard/Dashboard';
import Profile from './views/after-login/Profile/Profile';
import PrivateRoute from './views/after-login/PrivateRoute/PrivateRoute';
import NonAuthRoute from './views/auth/NonAuthRoute/NonAuthRoute';
import ResetPassword from './views/auth/ResetPassword/ResetPassword';
import Course from './views/cms/Course/Course';
import ChangePassword from './views/after-login/ChangePassword/ChangePassword';
import CourseDetails from './views/cms/CourseDetails/CourseDetails';
import MyEnquiry from './views/after-login/MyEnquiry/MyEnquiry';
import EnrolledCourse from './views/after-login/EnrolledCourse/EnrolledCourse';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  //declare loader state
  let [isLoading, setIsloading] = useState(true);

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="App wrapper" id="mainPage">
      {isLoading ? <Loader /> : ''}
      <HashRouter>
        <Header />
        <Routes >
          {/* cms route  */}
          <Route exact path='/' element={<Home isShowLoader={setIsloading} />} />
          <Route exact path='/faqs' element={<FAQ isShowLoader={setIsloading} />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy isShowLoader={setIsloading} />} />
          <Route exact path='/contact-us' element={<Contact isShowLoader={setIsloading} />} />
          <Route exact path='/about-us' element={<About isShowLoader={setIsloading} />} />
          <Route exact path='/course' element={<Course isShowLoader={setIsloading} />} />
          <Route exact path='/course-details/:id' element={<CourseDetails isShowLoader={setIsloading} />} />

          {/* non auth route  */}
          <Route exact path='/login'
            element={
              <NonAuthRoute>
                <Login isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          <Route exact path='/register'
            element={
              <NonAuthRoute>
                <Register isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          <Route exact path='/forgot-password'
            element={
              <NonAuthRoute>
                <ForgotPassword isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          <Route exact path='/reset-password/:token/:email'
            element={
              <NonAuthRoute>
                <ResetPassword isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />

          {/* auth route  */}
          <Route exact path='/dashboard'
            element={
              <PrivateRoute>
                <Dashboard isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route exact path='/my-profile'
            element={
              <PrivateRoute>
                <Profile isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route exact path='/change-password'
            element={
              <PrivateRoute>
                <ChangePassword isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route exact path='/my-enquiry'
            element={
              <PrivateRoute>
                <MyEnquiry isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route exact path='/enrolled-course'
            element={
              <PrivateRoute>
                <EnrolledCourse isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
