import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import bannerImg from "../../../images/banner.jpg";
import axios from "axios";
import config from '../../../constants/Env';

function Banner() {
    let navigate = useNavigate();
    let [searchKeyword, setSearchKeyword] = useState("");
    let [searchCategory, setSearchKeywordCategory] = useState("");
    let [categoryData, setCategoryData] = useState([]);

    //change route function
    let changeRoute = () => {
        let path = "/course";

        if (searchKeyword != null && searchKeyword != "") {
            path = `/course?search_key=${searchKeyword}`;
        }
        if (searchCategory != null && searchCategory != "") {
            path = `/course?category=${searchCategory}`;
        }
        if (searchKeyword != null && searchKeyword != "" && searchCategory != null && searchCategory != "") {
            path = `/course?search_key=${searchKeyword}&category=${searchCategory}`;
        }

        navigate(path);
    }

    let getCategory = () => {
        axios.post(config.apiBaseUrl + 'category', {})
            .then((response) => {
                if (response.data.status == 200) {
                    categoryData = response.data.data.enrollment_terms;
                    setCategoryData(categoryData);
                }
            }).catch(err => {
            })
    }

    useEffect(() => {
        getCategory();
    }, [])

    //redirect page by pressing enter key
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            changeRoute();
        }
    }

    let onSelectionChange = (e) => {
        searchCategory = e.target.value;
        setSearchKeywordCategory(searchCategory);
        changeRoute();
    }

    return (
        <section className="banner-sec">
            <div className="banner-form">
            </div>

            <div className="banner-pic">
                <div id="HomeBanner" className="">
                    <div className="item">
                        <img className="owl-lazy" src={bannerImg} alt="Banner" />
                    </div>
                </div>
            </div>

            <div className="banner-info">
                <div className="container">
                    <div className="baner-cntent">
                        <div className="banner-inner">
                            <h2>Learn from anywhere, anytime</h2>
                            <p>Learn your favourite course, build your skills. <br />Enroll course online from world-class instructors.</p>
                            <div className="form-group">
                                <div className="bnrsrch">
                                    <select id="propertyType" name="propertyType" className="opnbold ng-untouched ng-pristine ng-valid" defaultValue="" value={searchCategory} onChange={e => { onSelectionChange(e) }}>
                                        <option className="opnbold">Categories</option>
                                        {categoryData &&
                                            categoryData.map((item, i) => {
                                                return (item?.id != 1 ? <option key={i} value={item?.id} className="opnbold" onClick={() => changeRoute()}>{item?.name}</option> : <></>)
                                            })
                                        }
                                    </select>
                                    <input id="autocomplete-inp" type="text"
                                        className="gpsic ng-untouched ng-pristine ng-valid pac-target-input" placeholder="Search Training Topics" onChange={e => setSearchKeyword(e.target.value)} value={searchKeyword} onKeyDown={handleKeyDown} />
                                    <button type="button" className="searchbtn" onClick={() => changeRoute()}><i className="icon-srch"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner