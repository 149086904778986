import { React, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import bannerImg from "../images/banner.jpg";

function Breadcrumb() {
    let location = useLocation();
    let navigate = useNavigate();
    let [pathNameArr, setPathNameArr] = useState([]);

    useEffect(() => {
        //get cuttent path and set breadcrumbs
        let paramArr = location.pathname.split("/");
        paramArr.shift();

        if (paramArr.length > 0) {
            let finalArr = [];
            let pathURL = "";

            for (let p in paramArr) {

                let originalPathName = paramArr[p];
                let currentPathName = paramArr[p].split("-").join(" ").split("/").join("").split("and").join(" & ");
                const arr = currentPathName.split(" ");

                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }

                let finalPathName = arr.join(" ");

                if (finalPathName === 'Faqs') {
                    finalPathName = 'Frequently Asked Questions';
                }

                pathURL = pathURL + '/' + originalPathName;

                finalArr.push({
                    'id': parseInt(p),
                    'originalPathName': originalPathName,
                    'finalPathName': finalPathName,
                    'pathURL': pathURL
                });
            }
            console.log(finalArr)

            if (finalArr.length > 0 && finalArr[0].originalPathName == 'course-details') {
                const search = window.location.search;
                const params = new URLSearchParams(search);
                const cName = params.get('name');
                finalArr[1].finalPathName = cName;
            }
            pathNameArr = finalArr
            setPathNameArr(pathNameArr);
        }
    }, [location]);

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    return (
        <div className="">
            <section className="banner-sec iner-page">
                <div className="banner-form">
                </div>
                <div className="banner-pic">
                    <div id="HomeBanner" className="">
                        <div className="item">
                            <img src={bannerImg} />
                        </div>
                    </div>
                </div>
                <div className="banner-info">
                    <div className="container">
                        <div className="baner-cntent">
                            <div className="banner-inner">
                                <h2>
                                    <span>{pathNameArr.length > 0 && pathNameArr[0].finalPathName ? pathNameArr[0].finalPathName : ''}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="breadcrumba">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="" onClick={(e) => changeRoute(e, '/')}><span className="icon-Vector"></span></a></li>
                        {
                            pathNameArr.map(item =>
                                item.finalPathName !== '' ? <li key={item.id} className="breadcrumb-item active">
                                    <span aria-current="page" onClick={(e) => changeRoute(e, item.pathURL)}>{item.finalPathName}</span>
                                </li> : ''
                            )
                        }
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumb