import React from 'react';
import { Navigate } from 'react-router-dom';

const NonAuthRoute = ({ children }) => {
    const token = localStorage.getItem('access_token');

    if (token != null && token != "") {
        return <Navigate to="/dashboard"/>;
      }
      return children;
};

export default NonAuthRoute;