import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';

function Login({ isShowLoader }) {
  //declare form field
  let [formValue, setFormValue] = useState({
    "email": "",
    "password": ""
  });
  let [passwordType, setPasswordType] = useState('password');
  const navigate = useNavigate();

  //form submit function
  let formSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    let isValidate = formValidation(formValue);

    if (isValidate) {
      isShowLoader(true);
      axios.post(config.apiBaseUrl + 'auth/login', formValue)
        .then((response) => {
          isShowLoader(false);
          toast.dismiss();
          if (response.data.status == 200) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('user_details', JSON.stringify(response.data.user));

            let routeUrl = localStorage.getItem('route_url');
            if (routeUrl != null && routeUrl != "") {
              navigate(routeUrl);
            } else {
              navigate('/dashboard');
            }

            return toast.success(response.data.response);
          } else {
            toast.error(response.data.response);
          }
        }).catch(err => {
          isShowLoader(false);
          toast.error(variables.someErrorOccurred);
        })
    }
  }

  //form validation function
  let formValidation = (data) => {
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (data.email === null || data.email === "") {
      toast.error("Email is required");
      return false;
    }
    if (!emailPattern.test(data.email)) {
      toast.error("Enter valid email");
      return false;
    }
    if (data.password === null || data.password === "") {
      toast.error("Password is required");
      return false;
    }
    return true;
  }

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  useEffect(() => {
    isShowLoader(false);
  }, [])

  return (
    <div>
      <section className="loginfrom page_section mb-5 mt-5">
        <div className="inner_page mb-4">
          <div className="container">
            <div className="page_content">
              <h2 className="page-heading">Login</h2>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="">
            <div className="loginlayer">
              <form className="contactusform">
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Email*" type="text" name="email" onChange={e => setFormValue({ ...formValue, email: e.target.value })} value={formValue.email} />
                  </div>
                </div>
                <div className="row eye mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Password*" type={passwordType} name="password" onChange={e => setFormValue({ ...formValue, password: e.target.value })} value={formValue.password} />
                  </div>
                  <span className="eyeicon">
                    {passwordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setPasswordType('password')}></i>}
                  </span>
                </div>
                <a href="" className="submit_button submitbtn" onClick={(e) => formSubmit(e)}>Login</a>
              </form>
              <div className="">
                <a href="" className=" submitbtn" onClick={(e) => changeRoute(e, '/forgot-password')}>Forgot Password?</a>
              </div>
              <div className="">
                <a href="" className=" submitbtn" onClick={(e) => changeRoute(e, '/register')}>
                  Don’t have an account? SIGN UP</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login