import { React, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import variables from '../constants/Variables';
import siteLogo from "../images/logo.jpg";
import axios from "axios";
import config from '../constants/Env';

function Header() {
	//it is used for navigation
	const navigate = useNavigate();
	let location = useLocation();
	let [currentPathName, setcurrentPathName] = useState("/");
	let [isLogin, setIsLogin] = useState(false);
	let [categoryData, setCategoryData] = useState([]);
	let [submenuClass, setSubmenuClass] = useState('');
	let [width, setWidth] = useState(window.innerWidth);

	//change route function
	let changeRoute = (e, path) => {
		e.preventDefault();
		if (path !== null && path !== "") {
			setcurrentPathName(path)
			navigate(path);
		}
	}

	//open side bar in mobile view
	let openNavBar = (e) => {
		e.preventDefault();

		$("body").toggleClass('layout-expanded2');

		$('.layoutovarlay2').on('click', function (e) {
			if ($("body").hasClass('layout-expanded2')) {
				$("body").removeClass('layout-expanded2');
			}
		});
	}

	// logout function 
	let logOut = (e) => {
		toast.dismiss();
		e.preventDefault();
		localStorage.clear();
		localStorage.removeItem('access_token');
		localStorage.removeItem('user_details');
		navigate('/login');
		return toast.success(variables.logoutSuccess);
	}

	let getCategory = () => {
		axios.post(config.apiBaseUrl + 'category', {})
			.then((response) => {
				if (response.data.status == 200) {
					categoryData = response.data.data.enrollment_terms;
					setCategoryData(categoryData);
				}
			}).catch(err => {
			})
	}

	useEffect(() => {
		setcurrentPathName(location.pathname);
		getCategory();

		//scroll to top
		window.scrollTo(0, 0);

		// Sticky Navigation
		let lastScrollTop = 0;
		document.addEventListener("scroll", function (e) {
			let header = document.querySelector('.headercontainer')
			let st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop) {
				if (window.pageYOffset > 0) {
					header.classList.add('sticky');
				}
				if (window.pageYOffset > 500) {
					header.classList.add('sticky-hide');
				}
			} else {
				if (window.pageYOffset == 0) {
					header.classList.remove('sticky');
				} else {
					header.classList.remove('sticky-hide');
				}
			}
			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
		}, false);

		$('body').prepend($('<div className="layoutovarlay"></div>'));
		$('.navigation').prepend($('<div className="layoutovarlay2"></div>'));
		$("body").removeClass('layout-expanded2');

		let userAccessToken = localStorage.getItem('access_token');

		if (userAccessToken != null && userAccessToken != "") {
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}

		setSubmenuClass('display-none');

	}, [location])

	let onClickCategory = (e) => {
		e.preventDefault();
		setSubmenuClass(submenuClass == 'display-block' ? 'display-none' : 'display-block');
	}

	$(window).resize(function () {
		setSubmenuClass("");
	});

	return (
		<header className="headercontainer">
			<div className="container containercls">
				<div className="row align-items-center">
					<div className="tophead">
						<div className="logoouter">
							<div className="logo">
								<a href="" onClick={(e) => changeRoute(e, '/')}><img src={siteLogo} alt="" /></a>
							</div>
						</div>
						<div className="rgistrbx">
							<div className="navigation">
								<div className="navigationouter">
									<div className="navigationbox">
										<div className="navuser">
											<div className="userimg">
												<img src={siteLogo} alt="Logo" />
											</div>

										</div>
										<div className="sf-menu-outer">
											<ul className="sf-menu">
												<li class={currentPathName === '/' ? 'active' : ''}><a href="" onClick={(e) => changeRoute(e, '/')}>Home</a>
												</li>
												<li class={currentPathName === '/course' ? 'has-submenu active' : 'has-submenu'}>
													<a href="" onClick={e => onClickCategory(e)}> Categories<span className="sub-toggle"><i className="fa fa-angle-down downarrowcls" aria-hidden="true"></i>
													</span></a>
													<ul class={"sub-menu" + " " + submenuClass}>
														{ categoryData &&
															categoryData.map((item, i) => {
																return (item.id != 1 ? <li key={i} value={item?.id} className="opnbold"><a href="" onClick={(e) => changeRoute(e, `/course?category=${item?.id}`)}>{item?.name}</a></li> : <></>)
															})
														}
														<li></li>
													</ul>
												</li>
												<li class={currentPathName === '/about-us' ? 'active' : ''}><a href="" onClick={(e) => changeRoute(e, '/about-us')}>About Us</a>
												</li>
												<li class={currentPathName === '/faqs' ? 'active' : ''}><a href="" onClick={(e) => changeRoute(e, '/faqs')}>FAQ</a>
												</li>
												<li class={currentPathName === '/contact-us' ? 'active' : ''}><a href="" onClick={(e) => changeRoute(e, '/contact-us')}>Contact Us</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div className='loginbtx'>
								{!isLogin ? <>
									<a href="" className="usrlog" onClick={(e) => changeRoute(e, '/login')}><span className="icon-usr"></span> Login</a>
									<a href="" className="rgistrbtn mr-2" onClick={(e) => changeRoute(e, '/register')}><span className="icon-edit"></span><em>Register</em></a>
										{/* {window.location.pathname !== "/" && */}
											<div className="googletranslateelement" id="google_translate_element"></div>
										{/* } */}
								</> :
									<>
										<a href="" className="rgistrbtn dashboardbtn" onClick={(e) => changeRoute(e, '/dashboard')}><em><i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard</em></a>
										<a href="" className="rgistrbtn mr-2" onClick={(e) => logOut(e)}><em>
											<i className="fa fa-sign-out" aria-hidden="true"></i> Logout</em>
										</a>
										{window.location.pathname !== "/" &&
											<div className="googletranslateelement" id="google_translate_element"></div>
										}

									</>}
							</div>

						</div>
						<a className="NavBar" href="" onClick={(e) => openNavBar(e)}><i className="icon icon-segment"></i></a>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header