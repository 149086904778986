import React from 'react'
import videoimg from "../../../images/video1.png";
import playIcon from "../../../images/play.png";

function CourseResource({ courseData }) {
    return (
        <div className="resources">
            <h4>Resources</h4>
            {
                courseData?.course_resourses?.length == 0 ? <span className='nodataavailable'>no data available</span> : <h6>Recorded video uploaded on LMS</h6>
            }
            
            <div className="video row">
                {
                    courseData?.course_resourses?.map((data) => {
                        return <div className="col-lg-4 col-md-4 col-sm-4 col-5 mb-3">
                            <div className="">
                                <div className="overlayy">
                                    <img src={data?.thumbnail_url} className="resourceimg" />
                                    {/* <img src={playIcon} className="paly" /> */}
                                </div>
                            </div>
                            <div className="">
                                <h5>{data.title}</h5>
                                <p>{data.text} </p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default CourseResource