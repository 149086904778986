import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import Breadcrumb from '../../../partials/Breadcrumb';

function Contact({ isShowLoader }) {

  //declare form field
  let [formValue, setFormValue] = useState({
    "first_name": "",
    "last_name": "",
    "email": "",
    "inquiry": "",
    "user_id": ""
  });

  //form submit function
  let formSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    let isValidate = formValidation(formValue);

    if (isValidate) {
      isShowLoader(true);
      axios.post(config.apiBaseUrl + 'contact-us', formValue)
        .then((response) => {
          isShowLoader(false);
          resetForm();
          return toast.success(response.data.response ? response.data.response : variables.enquirySubmitSuccess);
        }).catch(err => {
          isShowLoader(false);
          toast.error(variables.someErrorOccurred);
        })
    }
  }

  //form validation function
  let formValidation = (data) => {
    let namePattern = /[^a-zA-Z ]/g
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (data.first_name === null || data.first_name === "") {
      toast.error("First name is required");
      return false;
    }
    if (data.last_name === null || data.last_name === "") {
      toast.error("Last name is required");
      return false;
    }
    if (data.email === null || data.email === "") {
      toast.error("Email is required");
      return false;
    }
    if (!emailPattern.test(data.email)) {
      toast.error("Enter valid email");
      return false;
    }
    if (data.inquiry === null || data.inquiry === "") {
      toast.error("Message is required");
      return false;
    }
    return true;
  }

  //reset from function
  let resetForm = () => {
    setFormValue({
      "first_name": "",
      "last_name": "",
      "email": "",
      "inquiry": "",
      "user_id": ""
    })
  }

  useEffect(() => {
    isShowLoader(true);
    let userData = localStorage.getItem('user_details');
    if (userData != null && userData != "") {
      userData = JSON.parse(userData);
      setFormValue({ ...formValue, user_id: userData.id, email: userData.email })
    }

    setTimeout(() => {
      isShowLoader(false);
    }, 600);
  }, [])

  return (
    <div>
      <Breadcrumb />
      <section className="contactus page_section mb-5">
        <div className="inner_page mb-4">
          <div className="container">

            <div className="page_content">
              <h2 className="page-heading">Contact Us</h2>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <form className="contactusform leftcontact">
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="First Name*" type="text" name="first_name" autoComplete="email" onChange={e => setFormValue({ ...formValue, first_name: e.target.value })} value={formValue.first_name} />
                  </div>
                  <div className="col">
                    <input className="form-control" placeholder="Last Name*" type="text" name="last_name" autoComplete="email" onChange={e => setFormValue({ ...formValue, last_name: e.target.value })} value={formValue.last_name} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Email*" type="text" name="email" onChange={e => setFormValue({ ...formValue, email: e.target.value })} value={formValue.email} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <textarea className="form-control" rows="4" placeholder="Message*" type="text" name="inquiry" onChange={e => setFormValue({ ...formValue, inquiry: e.target.value })} value={formValue.inquiry}></textarea>
                  </div>
                </div>
                <a href="" className="submit_button submitbtn" onClick={(e) => formSubmit(e)}>Submit</a>
              </form>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15762.448425563181!2d38.7635294!3d9.0077931!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b846aaaaaaaef%3A0xee1608cfd0234c79!2sGebeya!5e0!3m2!1sen!2ske!4v1707785096050!5m2!1sen!2ske" width="600" height="450" style={{ "border": 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact