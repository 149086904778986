import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import Sidebar from '../../../partials/Sidebar';
import noImage from "../../../images/no-image.jpeg";
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from 'react-router-dom';

function EnrolledCourse({ isShowLoader }) {
    let [pageCount, setPageCount] = useState(0);
    let [dataPerPage, setDataPerPage] = useState(10);
    let [currentPage, setCurrentPage] = useState(0);
    let [totalCount, setTotalCount] = useState(0);
    let [pageData, setPageData] = useState([]);
    const navigate = useNavigate();

    //get data function
    let getPageData = () => {
        isShowLoader(true);
        const URL = config.apiBaseUrl + 'auth/enroll-course-list';
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        let payload = {
            "data_per_page": dataPerPage,
            "page": currentPage + 1
        }

        axios.post(URL, payload, httpConfig)
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    totalCount = response.data.data.total;
                    setTotalCount(totalCount);
                    pageCount = Math.ceil(totalCount / dataPerPage);
                    setPageCount(pageCount);
                    pageData = response.data.data;

                    if (pageData.length > 0) {
                        for (let i in pageData) {
                            pageData[i].short_content = "";
                            pageData[i].isShowReadMore = false;

                            if (pageData[i].course_content != null && pageData[i].course_content != "") {
                                pageData[i].short_content = pageData[i].course_content.slice(0, 70) + '...';
                                pageData[i].isShowReadMore = true;
                            }
                        }
                    }

                    setPageData(pageData);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    const handlePageClick = (e) => {
        currentPage = e.selected;
        setCurrentPage(e.selected);
        getPageData();
    }

    let readMoreFunc = (i) => {
        pageData[i].short_content = pageData[i].course_content;
        pageData[i].isShowReadMore = false;
        setPageData([...pageData]);
    }

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    let goToCourse = (e, data) => {
        e.preventDefault();
        window.open(data?.url + data?.course_id, '_blank')
    }

    useEffect(() => {
        isShowLoader(false);
        getPageData();
    }, [])

    return (
        <section className='afterlogn-sec'>
            <div className="container">
                <div className="edtfrmbox">
                    <div className="row">
                        <div className="col-lg-3">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9">
                            <div className="rightprofile">
                                <div className="listdatacls">
                                    <div className="card">
                                        <div className="card-header" ><span className='title'>Enrolled Course</span> </div>
                                    </div>
                                    <div className="tablecls">
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width={50}>#</th>
                                                            <th scope="col" width={120}>Course Name</th>
                                                            <th scope="col" width={50}>Image</th>
                                                            <th scope="col" width={80}>Date</th>
                                                            <th scope="col" width={80}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pageData.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <th scope="row">{i + 1}</th>
                                                                        <td>{data.course_name ? data.course_name : ''}</td>
                                                                        <td>
                                                                            <img width={80} height={50} src={data.course_image ? data.course_image : noImage} />
                                                                        </td>
                                                                        <td>{data.created_at.split(" ")[0]}</td>
                                                                        <td>
                                                                            {/* <i className="fa fa-eye cursor-pointer" aria-hidden="true" onClick={(e) => changeRoute(e, `/course-details/${data.course_id}?name=${data?.course_name}`)}></i>
                                                                             */}
                                                                            <button type='button' className='ml-2 coursedetailsbtn' onClick={e => goToCourse(e, data)}> Go to Course</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            totalCount == 0 ? <tr><td className="text-center" colSpan={5}>No record found</td></tr> : ""
                                                        }
                                                    </tbody>
                                                </table>
                                                {/* <div className="mt-2">
                                                    {totalCount > 10 ? <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel="Next >"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        forcePage={currentPage}
                                                        pageCount={pageCount}
                                                        previousLabel="< Previous"
                                                        containerClassName={"pagination"}
                                                    /> : ''}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default EnrolledCourse