import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import variables from '../../../constants/Variables';
import config from '../../../constants/Env';
import Breadcrumb from '../../../partials/Breadcrumb';

function PrivacyPolicy({ isShowLoader }) {
    const [dataList, setDataList] = useState([]);

    //get data function
    let getPageData = () => {
        toast.dismiss();
        axios.post(config.apiBaseUrl + 'page/privacy-policy', {})
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    setDataList(response.data.data[0]);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    //call get data function
    useEffect(() => {
        isShowLoader(true);
        getPageData();
    }, [])

    return (
        <div>
            <Breadcrumb />
            <section className='terms_section page_section'>
                <div className="inner_page mb-4">
                    <div className="container">
                        <div className="page_content">
                            <h1 className="page-heading">Privacy Policy</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <p dangerouslySetInnerHTML={{ __html: dataList.page_content ? dataList.page_content : '' }}></p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy