import { React, useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import Sidebar from '../../../partials/Sidebar';
import variables from '../../../constants/Variables';

function ChangePassword({ isShowLoader }) {
    //declare form field
    let [formValue, setFormValue] = useState({
        "old_password": "",
        "new_password": "",
        "conf_new_password": ""
    });
    let [oldPasswordType, setOldPasswordType] = useState('password');
    let [newPasswordType, setNewPasswordType] = useState('password');
    let [confPasswordType, setConfPasswordType] = useState('password');

    //form submit function
    let formSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();

        let isValidate = formValidation(formValue);

        if (isValidate) {
            isShowLoader(true);
            const URL = config.apiBaseUrl + 'auth/change-password';
            const httpConfig = {
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
            };
            axios.post(URL, formValue, httpConfig)
                .then((response) => {
                    isShowLoader(false);
                    if (response.data.status == 200) {
                        toast.success(response.data.response);

                        setFormValue({
                            "old_password": "",
                            "new_password": "",
                            "conf_new_password": ""
                        });
                        setOldPasswordType('password');
                        setNewPasswordType('password');
                        setConfPasswordType('password');

                    } else {
                        toast.error(response.data.response);
                    }
                }).catch(err => {
                    isShowLoader(false);
                    toast.error(variables.someErrorOccurred);
                })
        }
    }

    //form validation function
    let formValidation = (data) => {
        if (data.old_password === null || data.old_password === "") {
            toast.error("Old password is required");
            return false;
        }
        if (data.new_password === null || data.new_password === "") {
            toast.error("New password is required");
            return false;
        }
        if (data.conf_new_password === null || data.conf_new_password === "") {
            toast.error("New confirm password is required");
            return false;
        }
        if (data.new_password !== data.conf_new_password) {
            toast.error("Confirm password does not match");
            return false;
        }
        return true;
    }

    useEffect(() => {
        isShowLoader(false);
    }, [])

    return (
        <section className='afterlogn-sec'>
            <div className="container">
                <div className="edtfrmbox">
                    <div className="row">
                        <div className="col-lg-3">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9">
                            <div className="rightprofile">
                                <h3>Change Password</h3>
                                <div className="row">
                                    <div className="col-sm-6 dasheye">
                                        <label className='fieldlabel'>Old Password*</label>
                                        <input className="form-control" placeholder="Old Password" type={oldPasswordType} name="old_password" onChange={e => setFormValue({ ...formValue, old_password: e.target.value })} value={formValue.old_password} />
                                        <span className="eyeicon">
                                            {oldPasswordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setOldPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setOldPasswordType('password')}></i>}
                                        </span>
                                    </div>
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6 dasheye formfielddmargintopmoile mt-3">
                                        <label className='fieldlabel'>New Password*</label>
                                        <input className="form-control" placeholder="New Password" type={newPasswordType} name="new_password" onChange={e => setFormValue({ ...formValue, new_password: e.target.value })} value={formValue.new_password} />
                                        <span className="eyeicon">
                                            {newPasswordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setNewPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setNewPasswordType('password')}></i>}
                                        </span>
                                    </div>
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6 dasheye mt-3">
                                        <label className='fieldlabel'>New Confirm Password*</label>
                                        <input className="form-control" placeholder="New Confirm Password" type={confPasswordType} name="conf_new_password" onChange={e => setFormValue({ ...formValue, conf_new_password: e.target.value })} value={formValue.conf_new_password} />
                                        <span className="eyeicon">
                                            {confPasswordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setConfPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setConfPasswordType('password')}></i>}
                                        </span>
                                    </div>
                                </div>
                                <a href="" className="submit_button updatebtn mt-3" onClick={(e) => formSubmit(e)}>Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword