import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from '../constants/Env';
import footerLogo from "../images/logo.jpg";
import lineImg from "../images/line.png";

function Footer() {
  //it is used for navigation
  const navigate = useNavigate();
  const [dataList, setDataList] = useState({});
  let [currentYear, setCurrentYear] = useState("")

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  //get data function
  let getPageData = () => {
    axios.post(config.apiBaseUrl + 'footer-data', {})
      .then((response) => {
        if (response.data.status == 200) {
          setDataList(response.data.data);
        }
      }).catch(err => {
      })
  }

  //call get data function
  useEffect(() => {
    getPageData();

    //set current year
    let date = new Date();
    setCurrentYear(date.getFullYear());
  }, [])

  return (
    <footer className="footer-sec">
      <div className="container containercls">
        <div className="row">
          <div className="col-lg-4">
            <div className="foterlogo">
              <a href="" onClick={(e) => changeRoute(e, '/')}><img src={footerLogo} /></a>
              <p>{dataList.short_about ? dataList.short_about : ''}</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-5">
            <div className="footer-contact">
              <h4>Quick Contact <span><img src={lineImg} /></span></h4>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-78">
                  <div className="usetmtx"><span className="usetmtxicon">
                    <i className="icon-loctn"></i></span><span className="usetmtxtext">
                      {dataList.site_address ? dataList.site_address : ''}</span>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-78">
                  <div className="usetmtx"><span className="usetmtxicon">
                    <i className="fa fa-whatsapp footerwhatsapp" aria-hidden="true"></i>
                  </span><span className="nmbtxt">
                      {dataList.whatsapp_phone_number ? dataList.whatsapp_phone_number : ''}</span>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-78">
                  <div className="usetmtx"><span className="usetmtxicon">
                    <i className="icon-cll"></i></span><span className="usetmtxtext">
                      {dataList.site_email
                        ? dataList.site_email
                        : ''}</span>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-78">
                  <div className="usetmtx"><span className="usetmtxicon">
                    <i className="icon-eml"></i></span><span className="nmbtxt">
                      {dataList.site_phone_number ? dataList.site_phone_number : ''}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-3">
            <div className="fotlink">
              <h4>Quick Links <span><img src={lineImg} /></span></h4>
              <ul>
                <li><a href="" onClick={(e) => changeRoute(e, '/about-us')}>About Us</a></li>
                <li><a href="" onClick={(e) => changeRoute(e, '/faqs')}>FAQ</a></li>
                <li><a href="" onClick={(e) => changeRoute(e, '/contact-us')}>Contact Us</a></li>
                <li><a href="" onClick={(e) => changeRoute(e, '/privacy-policy')}>Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="below-footer">
        <div className="container">
          <div className="below-inner">
            <p>Copyright © {currentYear} Mesirat. All Rights Reserved.</p>
            <ul>
              <li><a href={dataList.facebook_link ? dataList.facebook_link : ''} target='_blank'><span className="icon-fb"></span></a></li>
              <li><a href={dataList.twitter_link ? dataList.twitter_link : ''} target='_blank'><span className="icon-twtr"></span></a></li>
              <li><a href={dataList.instagram_link ? dataList.instagram_link : ''} target='_blank'><span className="icon-insta"></span></a></li>
              <li><a href={dataList.linkedin_link ? dataList.linkedin_link : ''} target='_blank'><span className="icon-linkedin"></span></a></li>
              <li><a href={dataList.youtube_link ? dataList.youtube_link : ''} target='_blank'><span className="icon-youtube"></span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer