import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';

function ResetPassword({ isShowLoader }) {
    //declare form field
    const [formValue, setFormValue] = useState({
        "email": "",
        "token": "",
        "password": "",
        "confpassword": ""
    });
    const navigate = useNavigate();
    const location = useLocation();
    let [passwordType, setPasswordType] = useState('password');
    let [confPasswordType, setConfPasswordType] = useState('password');

    //form submit function
    let formSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();

        let isValidate = formValidation(formValue);

        if (isValidate) {
            isShowLoader(true);
            axios.post(config.apiBaseUrl + 'auth/reset-password', formValue)
                .then((response) => {
                    isShowLoader(false);
                    if (response.data.status == 200) {
                        navigate('/login');
                        toast.success(response.data.response);
                    } else {
                        toast.error(response.data.response);
                    };
                }).catch(err => {
                    isShowLoader(false);
                    toast.error(variables.someErrorOccurred);
                })
        }
    }

    //form validation function
    let formValidation = (data) => {
        if (data.password === null || data.password === "") {
            toast.error("Password is required");
            return false;
        }
        if (data.confpassword === null || data.confpassword === "") {
            toast.error("Confirm password is required");
            return false;
        }
        if (data.password !== data.confpassword) {
            toast.error("Confirm password does not match");
            return false;
        }
        return true;
    }

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    useEffect(() => {
        isShowLoader(false);
        let paramValue = location.pathname.split("/").reverse();
        setFormValue({ ...formValue, email: paramValue[0].replace('%40', '@'), token: paramValue[1] });
    }, [])

    return (
        <div>
            <section className="loginfrom page_section mb-5">
                <div className="inner_page mb-4">
                    <div className="container">
                        <div className="page_content">
                            <h2 className="page-heading">reset Password</h2>
                        </div>
                    </div>
                </div>
                <div className="container mt-2">
                    <div className="">
                        <div className="loginlayer">
                            <form className="contactusform">
                                <div className="row eye mb-3">
                                    <div className="col">
                                        <input className="form-control" placeholder="Password*" type={passwordType} name="password" onChange={e => setFormValue({ ...formValue, password: e.target.value })} value={formValue.password} />
                                    </div>
                                    <span className="eyeicon">
                                        {passwordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setPasswordType('password')}></i>}
                                    </span>
                                </div>
                                <div className="row eye mb-3">
                                    <div className="col">
                                        <input className="form-control" placeholder="Confirm Password*" type={confPasswordType} name="confpassword" onChange={e => setFormValue({ ...formValue, confpassword: e.target.value })} value={formValue.confpassword} />
                                    </div>
                                    <span className="eyeicon">
                                        {confPasswordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setConfPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setConfPasswordType('password')}></i>}
                                    </span>
                                </div>
                                <a href="" className="submit_button submitbtn" onClick={(e) => formSubmit(e)}>Submit</a>
                            </form>
                            <div className="back">
                                <a href="" className="submitbtn" onClick={(e) => changeRoute(e, '/login')}>
                                    Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPassword