import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import Sidebar from '../../../partials/Sidebar';

function Profile({ isShowLoader }) {
  let [userData, setUserData] = useState({});
  let [categoryData, setCategoryData] = useState([]);
  let [languageData, setLanguageData] = useState([]);

  //declare form field
  let [formValue, setFormValue] = useState({
    "name": "",
    "email": "",
    "gender": "",
    "industry": "",
    "language_id": "",
    "address": "",
  });

  //form submit function
  let formSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    let isValidate = formValidation(formValue);

    if (isValidate) {
      isShowLoader(true);
      const URL = config.apiBaseUrl + 'auth/update-profile';
      const httpConfig = {
        headers: {
          'content-type': 'application/json',
          'authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
      };
      axios.post(URL, formValue, httpConfig)
        .then((response) => {
          isShowLoader(false);
          if (response.data.status == 200) {
            toast.success(response.data.response);

            var userName = document.getElementById('profile_user_name');
            userName.innerHTML = formValue.name;

          } else {
            toast.error(response.data.response);
          }
        }).catch(err => {
          isShowLoader(false);
          toast.error(variables.someErrorOccurred);
        })
    }
  }

  //form validation function
  let formValidation = (data) => {
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (data.name === null || data.name === "") {
      toast.error("Name is required");
      return false;
    }
    if (data.gender === null || data.gender === "") {
      toast.error("Gender is required");
      return false;
    }
    // if (data.industry === null || data.industry === "") {
    //   toast.error("Industry is required");
    //   return false;
    // }
    return true;
  }

  //get data function
  let getPageData = () => {
    isShowLoader(true);
    toast.dismiss();

    const URL = config.apiBaseUrl + 'auth/profile-details';
    const httpConfig = {
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
    };

    let userDetails = localStorage.getItem('user_details');
    userDetails = JSON.parse(userDetails);

    axios.post(URL, { user_id: userDetails.id }, httpConfig)
      .then((response) => {
        isShowLoader(false);
        if (response.data.status == 200) {
          let userData = response.data.user;
          setUserData(userData);

          setFormValue(
            {
              ...formValue,
              name: userData.name,
              email: userData.email,
              gender: userData.gender,
              industry: userData.industry,
              language_id: userData.language_id,
              address: userData.address,
            }
          );
        }
      }).catch(err => {
        isShowLoader(false);
        toast.error(variables.someErrorOccurred);
      })
  }

  let getCategory = () => {
    axios.post(config.apiBaseUrl + 'category', {})
      .then((response) => {
        if (response.data.status == 200) {
          categoryData = response.data.data.enrollment_terms;
          setCategoryData(categoryData);
        }
      }).catch(err => {
      })
  }

  let getLanguage = () => {
    axios.post(config.apiBaseUrl + 'language', {})
      .then((response) => {
        if (response.data.status == 200) {
          languageData = response.data.data;
          setLanguageData(languageData);
        }
      }).catch(err => {
      })
  }

  useEffect(() => {
    isShowLoader(false);
    getCategory();
    getPageData();
    getLanguage();
  }, [])

  return (
    <section className='afterlogn-sec'>
      <div className="container">
        <div className="edtfrmbox">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className="rightprofile">
                <h3>My Profile</h3>
                <div className="row">
                  <div className="col-sm-6">
                    <label className='fieldlabel'>Name*</label>
                    <input className="form-control" placeholder="Name*" type="text" name="name" autoComplete="email" onChange={e => setFormValue({ ...formValue, name: e.target.value })} value={formValue.name} />
                  </div>
                  <div className="col-sm-6 formfielddmargintopmoile">
                    <label className='fieldlabel'>Email*</label>
                    <input className="form-control" placeholder="Email*" type="text" name="email" onChange={e => setFormValue({ ...formValue, email: e.target.value })} value={formValue.email} readOnly />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className='fieldlabel'>Gender*</label>
                    <select name="gender" id="gender" className='form-control' defaultValue={formValue.gender ? formValue.gender : ''} value={formValue.gender} onChange={e => setFormValue({ ...formValue, gender: e.target.value })}>
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className='fieldlabel'>Regions, Zone, City*</label>
                    <input className="form-control" placeholder="Regions, Zone, City*" type="text" name="address" onChange={e => setFormValue({ ...formValue, address: e.target.value })} value={formValue.address} />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className='fieldlabel'>Industry*</label>
                    <select name="industry" id="industry" className='form-control' defaultChecked={formValue?.industry} value={formValue?.industry} onChange={e => setFormValue({ ...formValue, industry: e.target.value })}>
                      <option value="">Select Industry*</option>
                      {
                        categoryData.map((item, i) => {
                          return <option key={i} value={item?.id} className="opnbold">{item?.name}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className='fieldlabel'>Language*</label>
                    <select name="language_id" id="language_id" className='form-control' defaultChecked={formValue?.language_id} value={formValue?.language_id} onChange={e => setFormValue({ ...formValue, language_id: e.target.value })}>
                      <option value="">Select Language*</option>
                      {
                        languageData.map((item, i) => {
                          return <option key={i} value={item?.id} className="opnbold">{item?.name}</option>
                        })
                      }
                    </select>
                  </div>
                </div>
                <a href="" className="submit_button updatebtn mt-3" onClick={(e) => formSubmit(e)}>Update</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Profile