import { React, useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import variables from '../../../constants/Variables';
import config from '../../../constants/Env';
import noImage from "../../../images/no-image.jpeg";
import bannerImg from "../../../images/banner.jpg";


function Course({ isShowLoader }) {
    //declare state
    const navigate = useNavigate();
    let [searchKeyword, setSearchKeyword] = useState("");
    let [searchCategory, setSearchKeywordCategory] = useState("");
    let [dataList, setDataList] = useState([]);
    let [totalCourseData, setTotalCourseData] = useState([]);
    let [itemPerPage, setItemPerPage] = useState(8);
    let [categoryData, setCategoryData] = useState([]);
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();


    //get page data
    let getCourseData = () => {
        isShowLoader(true);
        toast.dismiss();
        setDataList([]);
        axios.post(config.apiBaseUrl + 'home-search', {
            "search_key": searchKeyword,
            "term_id": searchCategory
        })
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    totalCourseData = response.data.data;
                    setTotalCourseData(totalCourseData);
                    setDataList(totalCourseData.slice(0, itemPerPage));

                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    //load more data function
    let loadMoreData = () => {
        itemPerPage = itemPerPage + 8;
        setItemPerPage(itemPerPage);
        setDataList(totalCourseData.slice(0, itemPerPage));
    }

    //redirect page by pressing enter key
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getCourseData();
        }
    }

    let getCategory = () => {
        axios.post(config.apiBaseUrl + 'category', {})
            .then((response) => {
                if (response.data.status == 200) {
                    categoryData = response.data.data.enrollment_terms;
                    setCategoryData(categoryData);
                }
            }).catch(err => {
            })
    }

    let onChangeCategory = (value) => {
        console.log("tes:"+value);
        searchCategory = value;
        setSearchKeywordCategory(searchCategory);
        getCourseData();
    }

    useEffect(() => {
        const newCategoryId = searchParams.get('category');
        onChangeCategory(newCategoryId);
        isShowLoader(false);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let search_key = params.get('search_key');
        let category = params.get('category');
        console.log(params);

        if (search_key != null && search_key != "") {
            searchKeyword = search_key
            setSearchKeyword(searchKeyword);
        }
        if (category != null && category != "") {
            searchCategory = category;
            setSearchKeywordCategory(searchCategory);
        }
    }, [location])

    useEffect(() => {
        getCourseData();
        getCategory();
    }, [location])

    return (
        <div>
            <section className="banner-sec coursebanner">
                <div className="banner-form">
                </div>
                <div className="banner-pic">
                    <div id="HomeBanner" className="">
                        <div className="item">
                            <img className="owl-lazy" src={bannerImg} alt="Banner" />
                        </div>
                    </div>
                </div>

                <div className="banner-info">
                    <div className="container">
                        <div className="baner-cntent">
                            <div className="banner-inner">
                                <h2>Learn from anywhere, anytime</h2>
                                <div className="form-group">
                                    <div className="bnrsrch">
                                        <select id="propertyType" name="propertyType" className="opnbold ng-untouched ng-pristine ng-valid" defaultValue="" value={searchCategory} onChange={e => {
                                            setSearchKeywordCategory(e.target.value)
                                            onChangeCategory(e.target.value);
                                        }}>
                                            <option className="opnbold" value="">Categories</option>
                                            {
                                                categoryData.map((item, i) => {
                                                    return (item?.id != 1 ? <option key={i} value={item?.id} className="opnbold" onClick={() => changeRoute()}>{item?.name}</option> : <></>)
                                                })
                                            }
                                        </select>
                                        <input id="autocomplete-inp" type="text"
                                            className="gpsic ng-untouched ng-pristine ng-valid pac-target-input" placeholder="Search" onChange={e => setSearchKeyword(e.target.value)} value={searchKeyword} onKeyDown={handleKeyDown} />
                                        <button type="button" className="searchbtn" onClick={(e) => getCourseData(e)}><i class="icon-srch"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="main-boy-sec">
                <div class="container">
                    <div class="row">
                        {dataList.map((item, i) => {
                            return <div class="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
                                <div class="nula-sec">
                                    <img src={item.image_download_url ? item.image_download_url : noImage} alt="Course" />

                                    <div class="nuladetail">
                                        <p>{item.name}</p>
                                    </div>

                                    <a href="" class="pluslink" onClick={(e) => changeRoute(e, `/course-details/${item.id}?name=${item.name}`)}>
                                        <svg width="109" height="74" viewBox="0 0 109 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.954 61H0.5L3.31033 65.8989L109 74V0.511208C105.727 0.125443 97.3339 -0.414635 89.9413 0.511208C89.9413 8.8438 83.0109 10.9269 79.5456 10.9269H42.0055C34.613 10.9269 32.3798 17.485 32.1873 20.764V44.4888C32.1873 58.3765 21.3444 61.3858 15.954 61Z"
                                                fill="white" />
                                            <rect x="39" y="17" width="51" height="51" rx="25.5" fill="#337AB7" />
                                            <path
                                                d="M70.8574 43.6426H66.6426V47.8574C66.6426 48.4885 66.131 49 65.5 49C64.869 49 64.3574 48.4885 64.3574 47.8574V43.6426H60.1426C59.5115 43.6426 59 43.131 59 42.5C59 41.869 59.5115 41.3574 60.1426 41.3574H64.3574V37.1426C64.3574 36.5115 64.869 36 65.5 36C66.131 36 66.6426 36.5115 66.6426 37.1426V41.3574H70.8574C71.4885 41.3574 72 41.869 72 42.5C72 43.131 71.4885 43.6426 70.8574 43.6426Z"
                                                fill="white" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        })}
                        {totalCourseData.length == 0 ? <span className="nodatafound">No record found</span> : <span></span>}
                        {totalCourseData.length > 0 && itemPerPage < totalCourseData.length ? <button type="button" className="loadmorebtn mx-auto" onClick={() => loadMoreData()}>Load More</button> : <span></span>}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Course