import React from 'react';
import {Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('access_token');
    if (token == null || token == "") {
      toast.warning("Please login to continue");
      return <Navigate to="/login"/>;
    }

    return children;
};

export default PrivateRoute;