import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import variables from '../../../constants/Variables';
import config from '../../../constants/Env';
import Breadcrumb from '../../../partials/Breadcrumb';

function FAQ({ isShowLoader }) {
    //declare state
    let [dataList, setDataList] = useState([]);

    //get data function
    let getPageData = () => {
        toast.dismiss();
        axios.post(config.apiBaseUrl + 'faq-list', {})
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    setDataList(response.data.data);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    //call get data function
    useEffect(() => {
        isShowLoader(true);
        getPageData();
    }, [])

    return (
        <div>
            <Breadcrumb />
            <div className="faq_section">
                <div className="inner_page mb-4">
                    <div className="container">
                        <div className="page_content">
                            <h2 className="page-heading">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="accordion" id="accordionExample">
                            {dataList.map((item, i) => {
                                return <div className="accordion-item" key={i}>
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_' + i}
                                            aria-expanded={i == 0 ? 'true' : 'false'} aria-controls={'collapse_' + i}>
                                            {item.question}</button>
                                    </h2>
                                    <div id={'collapse_' + i} class={i == 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ