import { React, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../constants/Env';
import variables from '../constants/Variables';
import profileImg from "../images/no-image.jpeg";

function Sidebar() {
    //declare state
    const navigate = useNavigate();
    let location = useLocation();
    let [currentPathName, setcurrentPathName] = useState("/");
    let [userData, setUserData] = useState({});
    let [imageEvent, setImageEvent] = useState("");

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            setcurrentPathName(path);
            navigate(path);
        }
    }

    //change image
    let changeImageEvent = (e) => {
        let fileData = e.target.files[0];

        if (fileData.type.split('/')[0] != 'image') {
            return toast.error(variables.invalidFileFormat);
        }

        const reader = new FileReader()
        reader.onload = async (e) => {
            const imgFile = (e.target.result);
            setImageEvent(imgFile);
            uploadImage(fileData);
        };
        reader.readAsDataURL(fileData);
    }

    //upload Image
    let uploadImage = (file) => {
        toast.dismiss();
        let formData = new FormData();
        formData.append('profile_image', file);

        const URL = config.apiBaseUrl + 'auth/change-profile-photo';

        const httpConfig = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        axios.post(URL, formData, httpConfig)
            .then((response) => {
                if (response.data.status == 200) {
                    toast.success(response.data.response);
                    localStorage.setItem('user_details', JSON.stringify(response.data.user));
                } else {
                    toast.error(response.data.response);
                }
            }).catch(err => {
                toast.error(variables.someErrorOccurred);
            })
    }

    // logout function 
    let logOut = (e) => {
        e.preventDefault();
        toast.dismiss();
        localStorage.clear();
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_details');
        navigate('/login');
        return toast.success(variables.logoutSuccess);
    }

    //get data function
    let getPageData = () => {
        const URL = config.apiBaseUrl + 'auth/profile-details';
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        let userDetails = localStorage.getItem('user_details');
        userDetails = JSON.parse(userDetails);

        axios.post(URL, { user_id: userDetails.id }, httpConfig)
            .then((response) => {
                if (response.data.status == 200) {
                    localStorage.setItem('user_details', JSON.stringify(response.data.user));
                }
            }).catch(err => {
                localStorage.clear();
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_details');
                navigate('/login');
                return toast.success(variables.tokenExpired);
            })
    }

    useEffect(() => {
        setcurrentPathName(location.pathname);
        let userDetails = localStorage.getItem('user_details');
        if (userDetails != null && userDetails != "") {
            userDetails = JSON.parse(userDetails);
            if (userDetails.profile_image != null && userDetails.profile_image != "") {
                setImageEvent(config.assetsBaseUrl + userDetails.profile_image);
            }
            userData = userDetails;
            setUserData(userData);
        }
        getPageData();
    }, [location])

    return (
        <div className='profileleftpanel'>
            <div className="usercolumn">
                <div className="userimg">
                    <form className='useruploadimg '>
                        <i className="fa fa-camera" aria-hidden="true"></i>
                        <input type='file' id='updateprofilepic' className='' name='profile_image' accept="image/*" onChange={(e) => changeImageEvent(e)} />
                    </form>
                    <img width={200} src={imageEvent ? imageEvent : profileImg} />
                </div>
                <div className="username">
                    <span id='profile_user_name'>{userData.name}</span>
                </div>
            </div>
            <div className="usernavlist">
                <ul>
                    <li className={currentPathName == '/dashboard' ? 'active' : ''}>
                        <a href='' onClick={(e) => changeRoute(e, '/dashboard')} >
                            <i className="fa fa-tachometer mr-1" aria-hidden="true"></i> Dashboard</a>
                    </li>
                    <li className={currentPathName == '/my-profile' ? 'active' : ''}>
                        <a href='' onClick={(e) => changeRoute(e, '/my-profile')}>
                            <i className="fa fa-user mr-1" aria-hidden="true"></i> My Profile</a>
                    </li>
                    <li className={currentPathName == '/change-password' ? 'active' : ''}>
                        <a href='' onClick={(e) => changeRoute(e, '/change-password')}>
                            <i className="fa fa-key mr-1" aria-hidden="true"></i> Change Password</a>
                    </li>
                    <li className={currentPathName == '/enrolled-course' ? 'active' : ''}>
                        <a href='' onClick={(e) => changeRoute(e, '/enrolled-course')}>
                            <i className="fa fa-graduation-cap mr-1" aria-hidden="true"></i> Enrolled Course</a>
                    </li>
                    <li className={currentPathName == '/my-enquiry' ? 'active' : ''}>
                        <a href='' onClick={(e) => changeRoute(e, '/my-enquiry')}>
                            <i className="fa fa-envelope mr-1" aria-hidden="true"></i> My Enquiry</a>
                    </li>
                    <li>
                        <a href='' onClick={(e) => logOut(e)}>
                            <i className="fa fa-sign-out mr-1" aria-hidden="true"></i> Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar