import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import Sidebar from '../../../partials/Sidebar';
import ReactPaginate from 'react-paginate';

function MyEnquiry({ isShowLoader }) {
    let [pageCount, setPageCount] = useState(0);
    let [dataPerPage, setDataPerPage] = useState(10);
    let [currentPage, setCurrentPage] = useState(0);
    let [totalCount, setTotalCount] = useState(0);
    let [pageData, setPageData] = useState([]);

    //get data function
    let getPageData = () => {
        isShowLoader(true);
        toast.dismiss();

        const URL = config.apiBaseUrl + 'auth/enquiry-list';
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        let payload = {
            "data_per_page": dataPerPage,
            "page": currentPage + 1
        }

        axios.post(URL, payload, httpConfig)
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    totalCount = response.data.data.total;
                    setTotalCount(totalCount);
                    pageCount = Math.ceil(totalCount / dataPerPage);
                    setPageCount(pageCount);
                    pageData = response.data.data.data;

                    if (pageData.length > 0) {
                        for (let i in pageData) {
                            pageData[i].short_enquiry = "";
                            pageData[i].isShowReadMore = false;

                            if (pageData[i].inquiry != null && pageData[i].inquiry != "") {
                                pageData[i].short_enquiry = pageData[i].inquiry;
                                if (pageData[i].inquiry.length > 100) {
                                    pageData[i].short_enquiry = pageData[i].inquiry.slice(0, 100) + '...';
                                    pageData[i].isShowReadMore = true;
                                }
                            }
                        }
                    }

                    setPageData(pageData);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    const handlePageClick = (e) => {
        currentPage = e.selected;
        setCurrentPage(e.selected);
        getPageData();
    }

    let readMoreFunc = (i) => {
        pageData[i].short_enquiry = pageData[i].inquiry;
        pageData[i].isShowReadMore = false;
        setPageData([...pageData]);
    }

    useEffect(() => {
        isShowLoader(false);
        getPageData();
    }, [])

    useEffect(() => {
    }, [pageData])

    return (
        <section className='afterlogn-sec'>
            <div className="container">
                <div className="edtfrmbox">
                    <div className="row">
                        <div className="col-lg-3">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9">
                            <div className="rightprofile">
                                <div className="listdatacls">
                                    <div className="card">
                                        <div className="card-header" ><span className='title'>My Enquiry</span> </div>
                                    </div>
                                    <div className="tablecls">
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width={50}>#</th>
                                                            <th scope="col" width={120}>First Name</th>
                                                            <th scope="col" width={120}>Last Name</th>
                                                            <th scope="col" width={120}>Email</th>
                                                            <th scope="col">Message</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pageData.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <th scope="row">{i + 1}</th>
                                                                        <td>{data.first_name ? data.first_name : ''}</td>
                                                                        <td>{data.last_name ? data.last_name : ''}</td>
                                                                        <td>{data.email ? data.email : ''}</td>
                                                                        <td>{data.short_enquiry ? data.short_enquiry : ''}
                                                                            {
                                                                                data.isShowReadMore ? <span className='readmorecls' onClick={() => readMoreFunc(i)}>read more</span> : ''
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            totalCount == 0 ? <tr><td className="text-center" colSpan={5}>No record found</td></tr> : ""
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="mt-2">
                                                    {totalCount > 10 ? <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel="Next >"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        forcePage={currentPage}
                                                        pageCount={pageCount}
                                                        previousLabel="< Previous"
                                                        containerClassName={"pagination"}
                                                    /> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default MyEnquiry