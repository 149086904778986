import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import Sidebar from '../../../partials/Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';

function Dashboard({ isShowLoader }) {
    let [dataList, setDataList] = useState([]);
    let [dashboardCount, setDashboardCount] = useState({});
    const navigate = useNavigate();

    //get data function
    let getPageData = () => {
        // isShowLoader(true);
        const URL = config.apiBaseUrl + 'auth/dashboard';
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        let payload = {
            "data_per_page": 5,
            "page": 1
        }

        axios.post(URL, payload, httpConfig)
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {

                    dataList = response.data.enquiry_list;

                    if (dataList.length > 0) {
                        for (let i in dataList) {
                            dataList[i].short_enquiry = "";
                            dataList[i].isShowReadMore = false;

                            if (dataList[i].inquiry != null && dataList[i].inquiry != "") {
                                dataList[i].short_enquiry = dataList[i].inquiry;

                                if (dataList[i].inquiry.length > 100) {
                                    dataList[i].short_enquiry = dataList[i].inquiry.slice(0, 100) + '...';
                                    dataList[i].isShowReadMore = true;
                                }
                            }
                        }
                    }
                    setDashboardCount({
                        "enquiry_count": response.data.enquiry_count,
                        "enrollments_count": response.data.enrollments_count
                    })
                    setDataList(dataList);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    let readMoreFunc = (i) => {
        dataList[i].short_enquiry = dataList[i].inquiry;
        dataList[i].isShowReadMore = false;
        setDataList([...dataList]);
    }

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    useEffect(() => {
        isShowLoader(false);
        getPageData();
    }, [])

    return (
        <section className='afterlogn-sec'>
            <div className="container">
                <div className="edtfrmbox">
                    <div className="row">
                        <div className="col-lg-3">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9">
                            <div className="rightprofile">
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4"><div className="card text-white bg-info  mb-3"><div className="card-header">Enrolled Course</div><div className="card-body" tabindex="0"><h5 className="card-title">Total Enrolled </h5><h1>{dashboardCount?.enrollments_count ? dashboardCount?.enrollments_count : 0}</h1></div></div></div>

                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4"><div className="card text-white bg-warning mb-3"><div className="card-header">Enquiry</div><div className="card-body" tabindex="0"><h5 className="card-title">Total Enquiry</h5><h1>{dashboardCount?.enquiry_count ? dashboardCount?.enquiry_count : 0}</h1></div></div></div>
                                </div>
                                <div className="listdatacls">
                                    <div className="card">
                                        <div className="card-header dashboardcardarea">
                                            <span className='title'>Latest Enquiry</span>
                                            <span className='btn' onClick={(e) => changeRoute(e, `/my-enquiry`)}>View All</span>
                                        </div>
                                    </div>
                                    <div className="tablecls">
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width={50}>#</th>
                                                            <th scope="col" width={120}>First Name</th>
                                                            <th scope="col" width={120}>Last Name</th>
                                                            <th scope="col" width={120}>Email</th>
                                                            <th scope="col">Message</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            dataList.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <th scope="row">{i + 1}</th>
                                                                        <td>{data.first_name ? data.first_name : ''}</td>
                                                                        <td>{data.last_name ? data.last_name : ''}</td>
                                                                        <td>{data.email ? data.email : ''}</td>
                                                                        <td>{data.short_enquiry ? data.short_enquiry : ''}
                                                                            {
                                                                                data.isShowReadMore ? <span className='readmorecls' onClick={() => readMoreFunc(i)}>read more</span> : ''
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            dataList.length == 0 ? <tr><td className="text-center" colSpan={5}> found</td></tr> : ""
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Dashboard