import React from 'react'

function CourseAccrodion({ courseData }) {
    return (
        <div className="course-accro">
            <h4>Course content</h4>
            <div className="accordion" id="accordionExample">
                {
                    courseData?.content && courseData?.content.length > 0 ? courseData?.content.map((data, i) => {
                        return <div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_' + i}
                                    aria-expanded={i == 0 ? 'true' : 'false'} aria-controls={'collapse_' + i}>
                                    {data?.name}
                                </button>
                            </h2>
                            <div id={'collapse_' + i} class={i == 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="accro-detail">
                                        <ul>
                                            {
                                                data?.items.length > 0 ? data?.items.map((item, index) => {
                                                    return <li key={index}>
                                                        <a href="" className="d-flex align-items-center">
                                                            <div>
                                                                <p>{item?.title}</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }) : <></>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <></>
                }
                {
                    courseData?.content?.length == 0 ? <span className='nodataavailable'>no data available</span> : <></>
                }
            </div>
        </div>
    )
}

export default CourseAccrodion