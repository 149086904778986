import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';

function Register({ isShowLoader }) {
  //declare form field
  let [formValue, setFormValue] = useState({
    "name": "",
    "email": "",
    "gender": "",
    "industry": "",
    "language": "",
    "address": "",
    "special_group": "",
    "age": "",
    "phone": "",
    "password": "",
    "confpassword": ""
  });
  const navigate = useNavigate();
  let [passwordType, setPasswordType] = useState('password');
  let [confPasswordType, setConfPasswordType] = useState('password');
  let [categoryData, setCategoryData] = useState([]);
  let [languageData, setLanguageData] = useState([]);
  let [regionData, setRegionData] = useState([
    {id: 1, name: "Afar"},
    {id: 2, name: "Amhara"},
    {id: 3, name: "Gambella"},
    {id: 4, name: "Oromia"},
    {id: 5, name: "Addis Ababa"},
    {id: 6, name: "Sidama"},
    {id: 7, name: "Central Ethiopia"},
    {id: 8, name: "Tigray"},
    {id: 9, name: "Benishangul -Gumuz"},
    {id: 10, name: "Harari"},
    {id: 11, name: "Somalia"},
    {id: 12, name: "Dire Dawa"},
    {id: 13, name: "South West"},
  ]);

  let [specialGroups, setSpecialGroups] = useState([
    {id: 1, name: "Displaced"},
    {id: 2, name: "Refugee"},
    {id: 3, name: "Disability"},
    {id: 4, name: "None"},
  ]);

  //form submit function
  let formSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    let isValidate = formValidation(formValue);

    if (isValidate) {
      isShowLoader(true);
      axios.post(config.apiBaseUrl + 'auth/register', formValue)
        .then((response) => {
          isShowLoader(false);
          if (response.data.status == 200) {
            resetForm();
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('user_details', JSON.stringify(response.data.user));
            navigate('/dashboard');
            return toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }).catch(err => {
          isShowLoader(false);
          toast.error(err.response.data.errors ? err.response.data.errors : variables.someErrorOccurred);
        })
    }
  }

  //form validation function
  let formValidation = (data) => {
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (data.name === null || data.name === "") {
      toast.error("Name is required");
      return false;
    }
    if (data.email === null || data.email === "") {
      toast.error("Email is required");
      return false;
    }
    if (!emailPattern.test(data.email)) {
      toast.error("Enter valid email");
      return false;
    }
    if (data.gender === null || data.gender === "") {
      toast.error("Sex is required");
      return false;
    }
    if (data.age === null || data.age === "") {
      toast.error("Age is required");
      return false;
    }
    if (data.phone === null || data.phone === "") {
      toast.error("Phone is required");
      return false;
    }
    // if (data.language === null || data.language === "") {
    //   toast.error("Language is required");
    //   return false;
    // }
    // if (data.industry === null || data.industry === "") {
    //   toast.error("Industry is required");
    //   return false;
    // }
    if (data.password === null || data.password === "") {
      toast.error("Password is required");
      return false;
    }
    if (data.confpassword === null || data.confpassword === "") {
      toast.error("Confirm password is required");
      return false;
    }
    if (data.password !== data.confpassword) {
      toast.error("Confirm password does not match");
      return false;
    }
    return true;
  }

  //reset from function
  let resetForm = () => {
    setFormValue({
      "name": "",
      "email": "",
      "gender": "",
      "industry": "",
      "address": "",
      "special_group": "",
      "age": "",
      "phone": "",
      "language": "",
      "password": "",
      "confpassword": ""
    })
  }

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  let getCategory = () => {
    axios.post(config.apiBaseUrl + 'category', {})
      .then((response) => {
        if (response.data.status == 200) {
          categoryData = response.data.data.enrollment_terms;
          setCategoryData(categoryData);
        }
      }).catch(err => {
      })
  }

  let getLanguage = () => {
    axios.post(config.apiBaseUrl + 'language', {})
      .then((response) => {
        if (response.data.status == 200) {
          languageData = response.data.data;
          setLanguageData(languageData);
        }
      }).catch(err => {
      })
  }

  useEffect(() => {
    isShowLoader(false);
    getCategory();
    getLanguage();
  }, [])

  return (
    <div>
      <section className="loginfrom page_section mb-5">
        <div className="inner_page mb-4">
          <div className="container">
            <div className="page_content">
              <h2 className="page-heading">Register</h2>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="loginlayer">
            <div className="">
              <form className="contactusform">
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Enter you full name*" type="text" name="name" onChange={e => setFormValue({ ...formValue, name: e.target.value })} value={formValue.name} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Email*" type="text" name="email" onChange={e => setFormValue({ ...formValue, email: e.target.value })} value={formValue.email} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Phone Number*" type="tel" name="phone"
                           onChange={e => setFormValue({...formValue, phone: e.target.value})} value={formValue.phone}/>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Age*" type="number" name="age"
                           onChange={e => setFormValue({...formValue, age: e.target.value})} value={formValue.age}/>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <select name="gender" id="gender" className='form-control' defaultChecked="" value={formValue.gender} onChange={e => setFormValue({ ...formValue, gender: e.target.value })}>
                      <option value="">Sex*</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="row mb-3">
                      <div className="col">
                        <select name="address" id="address" className='form-control' defaultChecked=""
                                value={formValue.address}
                                onChange={e => setFormValue({...formValue, address: e.target.value})}>
                          <option value="">Region*</option>
                          {
                            regionData.map((item, i) => {
                              return <option key={i} value={item?.id} className="opnbold">{item?.name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="row mb-3">
                      <div className="col">
                        <select name="special_group" id="special_group" className='form-control' defaultChecked=""
                                value={formValue.special_group}
                                onChange={e => setFormValue({...formValue, special_group: e.target.value})}>
                          <option value="">Population Segment*</option>
                          {
                            specialGroups.map((item, i) => {
                              return <option key={i} value={item?.id} className="opnbold">{item?.name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="row mb-3">
                      <div className="col">
                        <select name="language" id="language" className='form-control' defaultChecked=""
                                value={formValue.language}
                                onChange={e => setFormValue({...formValue, language: e.target.value})}>
                          <option value="">Language*</option>
                          {
                            languageData.map((item, i) => {
                              return <option key={i} value={item?.id} className="opnbold">{item?.name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row eye mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Password*" type={passwordType} name="password" onChange={e => setFormValue({ ...formValue, password: e.target.value })} value={formValue.password} />
                  </div>
                  <span className="eyeicon">
                    {passwordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setPasswordType('password')}></i>}
                  </span>
                </div>
                <div className="row eye mb-3">
                  <div className="col">
                    <input className="form-control" placeholder="Confirm Password*" type={confPasswordType} name="confpassword" onChange={e => setFormValue({ ...formValue, confpassword: e.target.value })} value={formValue.confpassword} />
                  </div>
                  <span className="eyeicon">
                    {confPasswordType == 'password' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setConfPasswordType('text')}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={(e) => setConfPasswordType('password')}></i>}
                  </span>
                </div>
                <a href="" className="submit_button submitbtn" onClick={(e) => formSubmit(e)}>Sign Up</a>
              </form>
              <div className="mt-4">
                <a href="" className=" submitbtn" onClick={(e) => changeRoute(e, '/login')}>
                  Already have an account? LOGIN</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Register