import { React, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import config from '../../../constants/Env';
import variables from '../../../constants/Variables';
import noImage from "../../../images/no-image.jpeg";
import courseImg from "../../../images/couse.png";
import Breadcrumb from '../../../partials/Breadcrumb';
import CourseAccrodion from './CourseAccrodion';
import CourseResource from './CourseResource';
import CourseInstruction from './CourseInstruction';

function CourseDetails({ isShowLoader }) {
    //declare state
    const navigate = useNavigate();
    const location = useLocation();
    let [paramID, setParamID] = useState('');
    let [courseData, setCourseData] = useState({});

    //get course details function
    let getCourseDetails = () => {
        isShowLoader(true);
        toast.dismiss();

        const URL = config.apiBaseUrl + `course-details`;
        const httpConfig = {
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };

        let payload = {
            "course_id": parseInt(paramID),
            "user_id": ""
        };

        let userData = localStorage.getItem('user_details');

        if (userData != null && userData != "") {
            userData = JSON.parse(userData);
            payload.user_id = userData.id;
        }

        axios.post(URL, payload, httpConfig)
            .then((response) => {
                isShowLoader(false);
                if (response.data.status == 200) {
                    setCourseData(response.data.data);
                }
            }).catch(err => {
                isShowLoader(false);
                toast.error(variables.someErrorOccurred);
            })
    }

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    useEffect(() => {
        isShowLoader(false);
        let paramValue = location.pathname.split("/").reverse();
        paramID = paramValue[0];
        setParamID(paramID);
        getCourseDetails();
        localStorage.removeItem('route_url');
    }, [])

    return (
        <div className=''>
            <div className="bradecrame">
                <div className="container">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="" onClick={(e) => changeRoute(e, '/')}><span className="icon-Vector"></span></a></li>
                                <li className="breadcrumb-item"><a href="" onClick={(e) => changeRoute(e, '/course')}>Browse</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{courseData?.name}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="" style={{ marginTop: 15 }}>
                <section className="course-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12 text-left">
                                <img src={courseData.image_download_url ? courseData.image_download_url : noImage} className="img-fluid courseimg" />
                                <div className="course-name">
                                    <h2>Entrepreneurship Mindset</h2>
                                    <h6>{courseData?.name}</h6>
                                </div>
                                <hr />
                                <CourseAccrodion courseData={courseData} />
                                <hr />
                                <hr />
                                <div className="book">
                                    <h6><a href="">Participants Work book is available on LMS</a></h6>
                                </div>
                                <hr />
                                <div className="Objectives">
                                    <h4>Course Objectives</h4>
                                    {
                                        courseData?.public_description ? <h6>By completing this workshop, you will be able to:</h6> : <span className='nodataavailable'>no data available</span>
                                    }
                                    <div className="Objectives-list">
                                        <span dangerouslySetInnerHTML={{ __html: courseData?.public_description }}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 text-left">
                                <CourseInstruction courseData={courseData} />
                                                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CourseResource courseData={courseData} />

        </div>
    )
}

export default CourseDetails